import React from 'react';
import PropTypes from 'prop-types';
import locationHOC from '../locationProvider/locationHOC';
import {navigateToParametrized, getFullPath} from '../../lib/url';
import {ROUTES} from '../../constants/navigation';
import {t} from '@lingui/macro';
import { notification } from 'antd';

/**
 * Components that displays error in communication with server. If there is no error redirects to home page.
 *
 * @fero
 */

class ErrorDisplay extends React.PureComponent {
    static propTypes = {
        error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    };

    redirectHome = () => {
        window.location.href = getFullPath(ROUTES.HOME);
    };

    showDialog = () => {
        const {location, error} = this.props;
        if(error == null || error == '') {
            // no error, no bother
            navigateToParametrized(location, ROUTES.HOME, {});
            return;
        }

        notification['error']({
            message:  t`Neočakávaná chyba`,
            description: error.message != null ? JSON.stringify(error.message) : JSON.stringify(error),
            onClose: this.redirectHome,
            duration: 10,
        });
    };

    componentDidMount() {
        this.showDialog();
    }

    componentDidUpdate() {
        this.showDialog();
    }

    render() {
        return null;
    }

}

export default locationHOC(ErrorDisplay);
import React from 'react';
import ErrorDisplay from './ErrorDisplay';
import {Trans, t} from '@lingui/macro';
import withDataHOC from '../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../constants/globalData';

/**
 * @fero
 */

class Page400 extends React.PureComponent {
    render() {
        const error = this.props[GLOBAL_DATA.ERROR];
        return <div className="text-center p-5">
            <h1>400</h1>
            <h1><Trans>V shopíku sa vyskytla nečakaná chyba.</Trans></h1>
            <span><Trans>Skúste znovu načítať stránku, alebo kontaktujte prosím administrátora.</Trans></span>
            <ErrorDisplay error={error}/>
        </div>;
    }

}

export default withDataHOC([GLOBAL_DATA.ERROR])(Page400);